$(document).ready(initPage);

function initPage() {
  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }
  checkScroll();
  $(window).scroll(function () {
    checkScroll();
  });
  $('.toggler').click(function () {
    $('body').toggleClass('is-menu');
  });
  const swiper = new Swiper('.swiper--slider', {
    slidesPerView: 'auto',
    loop: true,
    spaceBetween: 46,
    centeredSlides: true,
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 30,
      },
      1600: {
        spaceBetween: 46,
      },
    },
  });
}
